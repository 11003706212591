<template>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="events"
        item-key="idEvent"
        show-expand
        class="elevation-1"
        hide-default-footer
      >
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="invoiceHeaders"
            :items="item.Factures"
            item-key="idFacture"
            hide-default-footer
          >
          </v-data-table>
        </td>
      </template>
      </v-data-table>
    </v-card>
</template>

<script>
export default {
  props: ['events'],
  components: {
  },
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'left',
          filterable: false,
          value: 'idEvent'
        },
        { text: 'Label', value: 'Libelle' }
      ],
      invoiceHeaders: [
        {
          text: 'Invoice Id',
          align: 'left',
          filterable: false,
          value: 'idFacture',
          width: '200px'
        },
        {
          text: 'Due Date',
          align: 'left',
          filterable: false,
          value: 'Date',
          width: '200px'
        },
        {
          text: 'Amount',
          align: 'left',
          filterable: false,
          value: 'Amount',
          width: '200px'
        }
      ]
    }
  }
}
</script>
