export default [
  {
    date: '2019-07-01',
    events: [
      {
        idEvent: '1',
        Libelle: 'VIREMENT 1',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '2',
        Libelle: 'VIREMENT 2',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '3',
        Libelle: 'VIREMENT 3',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '4',
        Libelle: 'VIREMENT 4',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '5',
        Libelle: 'VIREMENT 5',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      }
    ]
  },
  {
    date: '2019-08-01',
    events: [
      {
        idEvent: '6',
        Libelle: 'VIREMENT 6',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '666', Date: '2020/02/13', Amount: '- 87.12' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '7',
        Libelle: 'VIREMENT 7',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '8',
        Libelle: 'VIREMENT 8',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '9',
        Libelle: 'VIREMENT 9',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '10',
        Libelle: 'VIREMENT 10',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      }
    ]
  },
  {
    date: '2019-09-01',
    events: [
      {
        idEvent: '11',
        Libelle: 'VIREMENT 11',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '12',
        Libelle: 'VIREMENT 12',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '13',
        Libelle: 'VIREMENT 13',
        date: '2019-07-01',
        Color: 'yellow',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      }
    ]
  },
  {
    date: '2019-10-01',
    events: [
      {
        idEvent: '14',
        Libelle: 'VIREMENT 14',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '15',
        Libelle: 'VIREMENT 15',
        date: '2019-07-01',
        Color: 'green',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '16',
        Libelle: 'VIREMENT 16',
        date: '2019-07-01',
        Color: 'yellow',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      },
      {
        idEvent: '17',
        Libelle: 'VIREMENT 17',
        date: '2019-07-01',
        Color: 'red',
        Factures: [{ idFacture: '987', Date: '2020/02/13', Amount: '123.30' }, { idFacture: '654', Date: '2020/02/13', Amount: '123.30' }]
      }
    ]
  }
]
