<template>
  <div>
    <eventsGraph v-on:change="changeGraphEvents"/>
    <div v-show="filteredGraphList.length > 0">
      <eventsSummary v-bind:events="filteredGraphList" ref="summary" v-bind:colorIndex="colorIndex" v-on:change="changeSummaryEvents"/>
      <eventsList v-bind:events="filteredSummaryList"/>
    </div>
  </div>
</template>

<script>
import eventsGraph from './events-graph.vue'
import eventsList from './events-list.vue'
import eventsSummary from './events-summary.vue'

export default {
  components: {
    eventsGraph,
    eventsSummary,
    eventsList
  },
  data () {
    return {
      filteredGraphList: [],
      filteredSummaryList: []
    }
  },
  methods: {
    changeGraphEvents (value, colorIndex) {
      this.colorIndex = colorIndex
      this.filteredGraphList = value
      this.$refs.summary.update(this.colorIndex)
    },
    changeSummaryEvents (value) {
      this.filteredSummaryList = value
    }
  }
}
</script>
