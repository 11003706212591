<template>
  <v-card>
    <v-card-actions>
      <v-checkbox
        v-model="viewGreen"
        class="greentext"
        color="success"
        :label="`Applied Cash (${green})`"
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-checkbox
        v-model="viewYellow"
        class="yellowtext"
        color="#f2e62b"
        :label="`Unapplied Cash (${yellow})`"
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-checkbox
        v-model="viewRed"
        class="redtext"
        color="red"
        :label="`Predicted (${red})`"
      ></v-checkbox>
    </v-card-actions>
    <v-card-actions>
      <v-text-field
        v-model="invoiceNumber"
        label="Invoice Number"
        :placeholder="$t('t.InvoiceNumber')"
        solo
      ></v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['events', 'colorIndex'],
  computed: {
    green: function () { return this.events.filter(e => e.Color === 'green').length },
    yellow: function () { return this.events.filter(e => e.Color === 'yellow').length },
    red: function () { return this.events.filter(e => e.Color === 'red').length }
  },
  mounted: function () {
    this.updateList()
  },
  methods: {
    updateList: function () {
      let filteredEvents = this.events
        .filter(e => (e.Color === 'green' && this.viewGreen) || (e.Color === 'yellow' && this.viewYellow) || (e.Color === 'red' && this.viewRed))

      if (this.invoiceNumber !== '') {
        filteredEvents = filteredEvents.filter(e => e.Factures.filter(f => f.idFacture.includes(this.invoiceNumber)).length > 0)
      }

      this.$emit('change', filteredEvents)
    },
    updateColor: function () {
      this.viewGreen = (this.colorIndex === 0)
      this.viewYellow = (this.colorIndex === 1)
      this.viewRed = (this.colorIndex === 2)
    },
    update: function (colorIndex) {
      // this.colorIndex = colorIndex
      this.updateColor()
      this.invoiceNumber = ''
      this.updateList()
    }
  },
  watch: {
    viewGreen: function () {
      this.updateList()
    },
    viewYellow: function () {
      this.updateList()
    },
    viewRed: function () {
      this.updateList()
    },
    invoiceNumber: function () {
      this.updateList()
    },
    events: function () {
    }
  },
  data () {
    return {
      viewGreen: true,
      viewYellow: true,
      viewRed: true,
      invoiceNumber: ''
    }
  }
}
</script>

<style scoped>
.greentext /deep/ label {
  color: greenyellow;
}
.yellowtext /deep/ label {
  color: yellow;
}
.redtext /deep/ label {
  color: red;
}
</style>
