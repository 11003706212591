<template>
  <v-card>
    <v-card-title>Events</v-card-title>
    <apexchart
      width="650px"
      height="200px"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
import Apexchart from 'vue-apexcharts'
import eventsData from '../account/summary/allocation/events.js'

export default {
  components: {
    Apexchart
  },
  data () {
    return {
      chartSeries: []
    }
  },
  methods: {
    goToEvent: function (event, chartContext, config) {
      const data = eventsData[config.dataPointIndex].events
      this.$emit('change', data, config.seriesIndex)
    },
    getDataForColor: function (color) {
      return eventsData.map(d => d.events.filter(e => e.Color === color).length)
    }
  },
  mounted: function () {
    this.chartSeries = [
      { name: 'green', data: this.getDataForColor('green') },
      { name: 'yellow', data: this.getDataForColor('yellow') },
      { name: 'red', data: this.getDataForColor('red') }
    ]
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          type: 'bar',
          stacked: true,
          events: {
            dataPointSelection: this.goToEvent
          }
        },
        colors: ['#23d135', '#f2e62b', '#f22b2b'],
        xaxis: {
          categories: [
            'Nov \'19',
            'Dec \'19',
            'Jan \'20',
            'Feb \'20'
          ]
        },
        legend: {
          show: false
        },
        fill: {
          opacity: 1
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        yaxis: {
          forceNiceScale: true,
          min: 0
        },
        theme: {
          mode: this.$vuetify.theme.dark ? 'dark' : 'light',
          palette: 'palette1'
        }
      }
    },
    series () {
      return this.chartSeries
    }
  }
}
</script>

<style lang="stylus" scoped>
/deep/.apexcharts-canvas.apexcharts-theme-dark
  background none
</style>
